import { QuestionAnswer } from '@/datatypes/Question';
import { GqlAllMyAnswers, GqlAllMyReports } from '@/gql/AllMyReports';
import { FilesTable } from '@/sync/Dexie';
import { AnswerCommand, Command } from '@/datatypes/CommandTypes';
import { GqlInspections } from '@/gql/AllInspections';
import { QuestionRuleData } from '@/answers/RuleTypes';

export function applyCommandToAnswer(
  command: AnswerCommand,
  answer: GqlAllMyAnswers
) {
  switch (command.cmd) {
    case 'AnswerInspectQuestion':
      answer.code = command.answer;
      break;
    case 'AnswerValueInspectQuestion':
      answer.inanswervalue = command.answerValue;
      // answer.code = command.answer;
      break;
    case 'CommentOnInspectAnswer':
      answer.inanswercomment = command.comment;
      break;
    case 'VesselCommentOnInspectAnswer':
      answer.inanswervesselcomment = command.vesselComment;
      break;
    case 'NoteOnInspectAnswer':
      answer.inanswernote = command.note;
      break;
    case 'ObservationOnInspectAnswer':
      answer.text = command.observation;
      break;
    case 'RectActionOnInspectAnswer':
      answer.inanswerrectaction = command.rectAction;
      break;
    case 'ObservationRectifiedOnInspectAnswer':
      answer.inanswerrect = command.rectified ? 1 : 0;
      break;
    case 'ObservationDowngradeAnswer':
      answer.inanswerdowngrade = command.downgrade ? 1 : 0;
      break;
    case 'InspectClearAnswerReview':
      answer.inanswerreviewstatus = 0x02;
      break;
    case 'AddJpgToInspectAnswer':
      answer.images.push({
        uuid: command.imageId,
        title: command.imageName,
      });
      break;
    case 'AddPdfToInspectAnswer':
      answer.files.push({
        uuid: command.fileId,
        title: command.fileName,
      });
      break;
    case 'DelFileFromInspectAnswer':
      let idx = answer.images.findIndex((i) => i.uuid === command.imageId);
      if (idx >= 0) {
        answer.images.splice(idx, 1);
      } else {
        idx = answer.files.findIndex((i) => i.uuid === command.imageId);
        if (idx >= 0) {
          answer.files.splice(idx, 1);
        }
      }
      break;
    case 'ReviewOnInspectAnswer':
      answer.inanswerreview = command.review;
      break;
  }
}

export function applyCommandToReport(
  cmd: Command,
  report: GqlAllMyReports,
  inspect: GqlInspections
) {
  switch (cmd.cmd) {
    case 'CreateInspectReport':
      report.uuid = cmd.reportId;
      report.inreporttype = cmd.inspectId;
      break;
    case 'UpdateInspectReport':
      if (cmd.clientId !== null) {
        report.inreportclient = cmd.clientId;
      }
      if (cmd.docCompanyId !== null) {
        report.inreportdoccompany = cmd.docCompanyId;
      }
      if (cmd.inspectDate !== null) {
        report.inreportdate = cmd.inspectDate;
      }
      if (cmd.inspectPort !== null) {
        report.summary = cmd.inspectPort;
      }
      if (cmd.notes !== null) {
        report.text = cmd.notes;
      }
      if (cmd.vesselName !== null) {
        report.title = cmd.vesselName;
      }
      if (cmd.vesselImo !== null) {
        report.code = cmd.vesselImo;
      }
      if (cmd.vesselClass !== null) {
        report.inreportvesselclass = cmd.vesselClass;
      }
      if (cmd.vesselDwt !== null) {
        report.inreportvesseldwt = cmd.vesselDwt;
      }
      if (cmd.vesselType !== null) {
        report.inreportvesseltype = cmd.vesselType;
      }
      if (cmd.vesselYear !== null) {
        report.inreportvesselyear = cmd.vesselYear;
      }
      if (cmd.verification !== null) {
        report.inreportverification = cmd.verification;
      }
      if (cmd.verificationDate !== null) {
        report.inreportverificationdate = cmd.verificationDate;
      }
      break;
    default:
      let answer = report.answers.find(
        (x) => x.inanswerquestion === cmd.questionId
      );

      if (cmd.cmd === 'AnswerValueInspectQuestion') {
        const q = inspect?.questions?.find((i) => i.uuid === cmd.questionId);
        let rules: QuestionRuleData = {};
        try {
          rules = JSON.parse(q?.inquestionrules);
        } catch (e) {
          // invalid rules
        }
        if (rules?.field) {
          report[rules.field] = cmd.answerValue;
        }
      }

      if (!answer) {
        answer = emptyAnswer(cmd.questionId);
        report.answers.push(answer);
      }

      applyCommandToAnswer(cmd, answer);
      break;
  }
}

export function applyFileToReport(file: FilesTable, report: GqlAllMyReports) {
  let answer = report.answers.find(
    (a) => a.inanswerquestion === file.questionId
  );
  if (!answer) {
    report.answers.push((answer = emptyAnswer(file.questionId)));
  }
  const obj =
    (file.type.split('/')[0] === 'image' && answer.images) || answer.files;
  if (!obj.find((i) => i.uuid === file.uuid)) {
    obj.push({ uuid: file.uuid, title: file.name });
  }
}

export function emptyReport(uuid: string): GqlAllMyReports {
  return {
    id: '',
    uuid,
    type: 'inreport',
    ver: 0,
    title: '',
    code: '',
    summary: '',
    text: '',
    keywords: '',
    inreportdate: '',
    inreportstatus: '0',
    inreporttype: '',
    inreportvesseldwt: '',
    inreportvesselfuel: '',
    inreportvesselhatch: '',
    inreportvesselwinches: '',
    inreportvesseltype: '',
    inreportvesselyear: '',
    inreportvesselclass: '',
    inreportdoccompany: '',
    inreportclient: '',
    inreportverification: 0,
    inreportverificationdate: '',
    answers: [],
  };
}

export function emptyAnswer(questionId: string): GqlAllMyAnswers {
  return {
    id: '',
    uuid: '',
    ver: 0,
    inanswervalue: '',
    inanswerrect: 0,
    inanswerrectaction: '',
    code: QuestionAnswer.Unanswered,
    images: [],
    inanswerquestion: questionId,
    inanswercause: '',
    inanswercomment: '',
    inanswervesselcomment: '',
    inanswernote: '',
    inanswerrootcause: '',
    inanswerdowngrade: 0,
    text: '',
    title: '',
    files: [],
    inanswerreview: '',
    inanswerreviewstatus: 0,
  };
}

export function cmdReportId(cmd: Command): string {
  switch (cmd.cmd) {
    case 'CreateInspectReport':
    case 'UpdateInspectReport':
      return cmd.reportId;
    default:
      return cmd.inspectId;
  }
}
